import { softShadows } from './shadows';
import { THEMES, COLORS } from '../constants';

const dark = {
  name: THEMES.DARK,
  overrides: {
    MuiTypography: { root: { color: COLORS.WAYPOINT_DARK_PRIMARY } },

    MuiInputBase: {
      input: {
        '&::placeholder': {
          opacity: 1,
          color: '#878b91',
        },
      },
    },
    MuiIconButton: {
      root: {
        color: COLORS.LAVENDER_GREY,
      },
    },

    MuiOutlinedInput: {
      root: {
        '&:hover .MuiOutlinedInput-notchedOutline': {
          // input is hovered over
          borderColor: COLORS.WAYPOINT_DARK_PRIMARY,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          // input is clicked
          borderColor: COLORS.WAYPOINT_DARK_PRIMARY,
        },
      },
      notchedOutline: {
        borderColor: COLORS.SILVER_MIST,
      },
    },
    MuiFormLabel: {
      root: {
        color: COLORS.SILVER_MIST,
        '&.MuiInputLabel-shrink': {
          // label when sitting above input
          color: COLORS.SILVER_MIST,
        },
      },
    },
    MuiSelect: {
      icon: {
        color: COLORS.SILVER_MIST,
      },
    },

    MuiListItem: {
      root: {
        '&.Mui-disabled': {
          opacity: 1,
          color: COLORS.SILVER_MIST,
        },
      },
    },
    MuiListItemIcon: {
      root: {
        color: COLORS.SILVER_MIST,
      },
    },

    MuiButton: {
      root: {
        color: COLORS.MIDNIGHT,
        boxShadow: 'none !important',
        transitionDuration: '0.05s',
        '&.MuiButton-containedSecondary:hover': {
          backgroundColor: '#85c7cd',
        },
        '&.MuiButton-textPrimary': {
          color: COLORS.WAYPOINT_DARK_PRIMARY,
        },

        '&.MuiButton-text.Mui-disabled': {
          color: COLORS.CARBON_GREY,
        },

        '&.MuiButton-outlined, &.MuiButton-contained': {
          borderRadius: '999px',
        },

        '&.MuiButton-outlinedPrimary': {
          borderColor: COLORS.SILVER_MIST,
          color: COLORS.WAYPOINT_DARK_PRIMARY,
          '&:hover': {
            background: COLORS.SILVER_MIST,
            color: COLORS.WHITE,
            border: `1px solid ${COLORS.SILVER_MIST}`,
          },
        },

        '&.MuiButton-containedPrimary': {
          background: COLORS.WAYPOINT_DARK_PRIMARY, // Change this line to specify the purple background color you want
          color: COLORS.MIDNIGHT,
          '&:hover': {
            border: 'none',
            background: COLORS.SILVER_MIST,
            color: COLORS.WHITE, // Set the text color to white for the primary button on hover
          },
        },

        '&.MuiButton-contained.Mui-disabled': {
          color: COLORS.PALE_STEEL,
          backgroundColor: COLORS.WAYPOINT_DARK,
          border: `1px solid ${COLORS.PALE_STEEL}`,
          opacity: 0.1,
        },

        '&.MuiButton-outlined.Mui-disabled': {
          color: COLORS.SILVER_MIST,
          backgroundColor: COLORS.WAYPOINT_DARK,
          borderColor: COLORS.SILVER_MIST,
          opacity: 0.5,
        },

        // 🎨 TODO: almost all buttons have color="secondary" and they need to be changed to primary
        // remove/update the below styling
        '&.MuiButton-outlinedSecondary': {
          border: `1px solid ${COLORS.SLATE_GREY}`,
          color: COLORS.WAYPOINT_LIGHT_PRIMARY,
          '&:hover': {
            background: COLORS.WAYPOINT_LIGHT_PRIMARY,
            color: COLORS.WHITE,
          },
        },
        '&.MuiButton-containedSecondary': {
          border: `1px solid ${COLORS.WAYPOINT_DARK_PRIMARY}`,
          background: COLORS.WAYPOINT_DARK_PRIMARY,
          color: COLORS.MIDNIGHT,
          '&:hover': {
            border: `1px solid ${COLORS.SILVER_MIST}`,
            background: COLORS.SILVER_MIST,
            color: COLORS.WHITE,
          },
        },
      },
    },

    MuiCheckbox: {
      colorPrimary: {
        color: COLORS.GOLDEN,
        '&.Mui-checked': {
          color: COLORS.GOLDEN,
        },
      },
      // 🎨 TODO: by default, <Checkbox /> components have color="secondary" but if we add a
      // secondary colour - this may become a confusing. Will need to add color="primary"
      // to all checkboxes
      colorSecondary: {
        color: COLORS.WAYPOINT_DARK_PRIMARY,
        '&.Mui-checked': {
          color: COLORS.WAYPOINT_DARK_PRIMARY,
        },
      },
    },
  },
  palette: {
    type: 'dark',
    action: {
      active: '#64AFB7',
    },
    background: {
      default: COLORS.MIDNIGHT_NAVY,
      dark: COLORS.WAYPOINT_DARK,
      paper: COLORS.WAYPOINT_DARK,
      logo: COLORS.WHITE,
      topBar: COLORS.MIDNIGHT_NAVY,
      card: '#26252C',
    },
    breadcrumb: {
      label: COLORS.LAVENDER_GREY,
    },
    primary: {
      main: COLORS.LAVENDER_GREY,
      light: COLORS.MIDNIGHT_LIGHT,
      success: COLORS.MINT,
    },
    secondary: {
      main: COLORS.WAYPOINT_DARK_PRIMARY,
      contrastText: '#fff',
    },
    tab: {
      bottom: COLORS.WAYPOINT_DARK_PRIMARY,
    },
    notification: {
      background: COLORS.WAYPOINT_DARK_PRIMARY,
      color: COLORS.MIDNIGHT,
    },
    warning: {
      main: '#e4c323',
    },
    unavailable: {
      main: '#e0e0e0',
    },
    error: {
      main: COLORS.CHILLI,
      contrastText: '#fff',
    },
    text: {
      primary: COLORS.LAVENDER_GREY,
      secondary: '#878b91',
      default: 'black',
    },
    header: {
      background: '#2D2D2D',
      main: '#E8E8E8',
    },
    leftNav: {
      primary: COLORS.DARK_GREY,
    },
    tableFirstRow: {
      borderTop: `2px solid ${COLORS.SILVER_MIST}`,
    },
    tableRowEven: {
      background: COLORS.WAYPOINT_DARK,
      borderTop: 'none',
      borderBottom: 'none',
      backgroundHover: COLORS.AZURE_SMOKE,
      color: COLORS.WHITE,
    },
    tableRowOdd: {
      background: COLORS.MIDNIGHT_NAVY,
      borderTop: 'none',
      borderBottom: 'none',
      backgroundHover: COLORS.AZURE_SMOKE,
      color: COLORS.WHITE,
    },
    navBar: {
      hoverColor: COLORS.WAYPOINT_DARK_PRIMARY,
      backgroundColor: COLORS.SLATE_GREY,
      activeColor: COLORS.WAYPOINT_DARK_PRIMARY,
    },
    stepper: {
      color: '#CAC4CF',
      labelColorCompletion: COLORS.LAVENDER_GREY,
      labelColorIncomplete: '#938F99',
      backgroundColor: '#494458',
      bgColorIncomplete: '#48454E',
    },
    userAvatar: {
      background: '#4B388C',
      color: COLORS.WAYPOINT_DARK_PRIMARY,
    },
  },
  shadows: softShadows,
};

export default dark;
