import { softShadows } from './shadows';
import { THEMES, COLORS } from '../constants';

const light = {
  name: THEMES.LIGHT,
  overrides: {
    MuiTypography: { root: { color: COLORS.MIDNIGHT } },

    MuiInputBase: {
      input: {
        '&::placeholder': {
          opacity: 1,
          color: '#878b91',
        },
      },
    },
    MuiIconButton: {
      root: {
        color: COLORS.MIDNIGHT,
      },
    },

    MuiOutlinedInput: {
      root: {
        '&:hover .MuiOutlinedInput-notchedOutline': {
          // input is hovered over
          borderColor: COLORS.WAYPOINT_LIGHT_PRIMARY,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          // input is clicked
          borderColor: COLORS.WAYPOINT_LIGHT_PRIMARY,
        },
      },
      notchedOutline: {
        borderColor: COLORS.DARK_GREY,
      },
    },
    MuiFormLabel: {
      root: {
        color: COLORS.DARK_GREY,
        '&.MuiInputLabel-shrink': {
          // label when sitting above input
          color: COLORS.DARK_GREY,
        },
      },
    },
    MuiSelect: {
      icon: {
        color: COLORS.DARK_GREY,
      },
    },

    MuiListItem: {
      root: {
        '&.Mui-disabled': {
          opacity: 1,
          color: COLORS.DARK_GREY,
        },
      },
    },
    MuiListItemIcon: {
      root: {
        color: COLORS.MIDNIGHT,
      },
    },

    MuiButton: {
      root: {
        color: COLORS.MIDNIGHT,
        boxShadow: 'none !important',
        transitionDuration: '0.05s',
        '&.MuiButton-containedSecondary:hover': {
          backgroundColor: '#85c7cd',
        },

        '&.MuiButton-textPrimary': {
          color: COLORS.WAYPOINT_LIGHT_PRIMARY,
        },

        '&.MuiButton-text.Mui-disabled': {
          color: COLORS.SILVER_MIST,
        },

        '&.MuiButton-outlined, &.MuiButton-contained': {
          borderRadius: '999px',
        },

        '&.MuiButton-outlinedPrimary': {
          color: COLORS.WAYPOINT_LIGHT_PRIMARY,
          '&:hover': {
            background: COLORS.MIDNIGHT,
            color: COLORS.WHITE,
          },
        },

        '&.MuiButton-containedPrimary': {
          border: `1px solid ${COLORS.WHITE}`,
          background: COLORS.WAYPOINT_LIGHT_PRIMARY, // Change this line to specify the purple background color you want
          color: COLORS.WHITE, // Set the text color to white for the primary button
          '&:hover': {
            border: `1px solid ${COLORS.MIDNIGHT}`,
            background: COLORS.MIDNIGHT,
            color: COLORS.WHITE, // Set the text color to white for the primary button on hover
          },
        },

        '&.MuiButton-contained.Mui-disabled': {
          color: COLORS.DARK_GREY,
          backgroundColor: COLORS.PORCELAIN_DARK,
          borderColor: COLORS.PORCELAIN_DARK,
        },

        '&.MuiButton-outlined.Mui-disabled': {
          color: COLORS.DARK_GREY,
          backgroundColor: COLORS.PORCELAIN_DARK,
          borderColor: COLORS.PORCELAIN_DARK,
        },

        // 🎨 TODO: almost all buttons have color="secondary" and they need to be changed to primary
        // remove/update the below styling
        '&.MuiButton-outlinedSecondary': {
          border: `1px solid ${COLORS.SLATE_GREY}`,
          color: COLORS.WAYPOINT_LIGHT_PRIMARY,
          '&:hover': {
            background: COLORS.WAYPOINT_LIGHT_PRIMARY,
            color: COLORS.WHITE,
          },
        },
        '&.MuiButton-containedSecondary': {
          border: `1px solid ${COLORS.WAYPOINT_LIGHT_PRIMARY}`,
          background: COLORS.WHITE,
          color: COLORS.WAYPOINT_LIGHT_PRIMARY,
          '&:hover': {
            border: `1px solid ${COLORS.WHITE}`,
            background: COLORS.WAYPOINT_LIGHT_PRIMARY,
            color: COLORS.WHITE,
          },
        },
      },
    },

    MuiCheckbox: {
      colorPrimary: {
        color: COLORS.CHARCOAL,
        '&.Mui-checked': {
          color: COLORS.CHARCOAL,
        },
      },
      // 🎨 TODO: by default, <Checkbox /> components have color="secondary" but if we add a
      // secondary colour - this may become a confusing. Will need to add color="primary"
      // to all checkboxes
      colorSecondary: {
        color: COLORS.WAYPOINT_LIGHT_PRIMARY,
        '&.Mui-checked': {
          color: COLORS.WAYPOINT_LIGHT_PRIMARY,
        },
      },
    },
  },
  palette: {
    type: 'light',
    action: {
      active: '#64AFB7',
    },
    background: {
      default: COLORS.WHITE,
      dark: COLORS.PORCELAIN,
      paper: COLORS.WHITE,
      logo: COLORS.WHITE,
      card: '#F8FAFA',
    },
    breadcrumb: {
      label: COLORS.SLATE_GREY,
    },
    primary: {
      main: COLORS.SLATE_GREY,
      light: COLORS.MIDNIGHT_LIGHT,
      success: COLORS.MINT,
    },
    secondary: {
      main: COLORS.WAYPOINT_LIGHT_PRIMARY,
      contrastText: '#fff',
    },
    tab: {
      bottom: COLORS.WAYPOINT_LIGHT_PRIMARY,
    },
    notification: {
      background: COLORS.WAYPOINT_LIGHT_PRIMARY,
      color: COLORS.WHITE,
    },
    warning: {
      main: '#e4c323',
    },
    unavailable: {
      main: '#e0e0e0',
    },
    error: {
      main: COLORS.CHILLI,
      contrastText: '#fff',
    },
    text: {
      primary: COLORS.CHARCOAL,
      secondary: '#878b91',
      default: 'black',
    },
    header: {
      background: '#2D2D2D',
      main: '#E8E8E8',
    },
    leftNav: {
      primary: COLORS.DARK_GREY,
    },
    tableFirstRow: {
      borderTop: `2px solid ${COLORS.SILVER_MIST}`,
    },
    tableRowEven: {
      background: '#f4f4f482',
      borderTop: 'none',
      borderBottom: 'none',
      backgroundHover: COLORS.SOFT_ORCHID,
      color: COLORS.WHITE,
    },
    tableRowOdd: {
      background: COLORS.WHITE,
      borderTop: 'none',
      borderBottom: 'none',
      backgroundHover: COLORS.SOFT_ORCHID,
      color: COLORS.WHITE,
    },
    navBar: {
      hoverColor: COLORS.WAYPOINT_LIGHT_PRIMARY,
      backgroundColor: COLORS.SOFT_LAVENDER,
      activeColor: COLORS.WAYPOINT_LIGHT_PRIMARY,
    },
    stepper: {
      color: '#48454E',
      labelColorCompletion: COLORS.SLATE_GREY,
      labelColorIncomplete: '#79757F',
      backgroundColor: '#E7DFF8',
      bgColorIncomplete: '#F8FAFA',
    },
    userAvatar: {
      background: COLORS.SOFT_LAVENDER,
      color: COLORS.WAYPOINT_LIGHT_PRIMARY,
    },
  },
  shadows: softShadows,
};

export default light;
